import { DateTime } from "luxon";
import { useAccountStore } from "../../../../stores/accountStore";
import { useBookingsCreatingStore } from "../../../../stores/planner";

class CreateActions {
  #id = Date.now() % 10000;

  get id() {
    return this.#id;
  }

  usersWithCreationBookings = (creationBookingsArray, filteredUsers) => {
    const userIndexes = [
      ...new Set(
        creationBookingsArray.map((booking) => {
          return booking[0];
        })
      ),
    ];

    return userIndexes.map((userIndex) => {
      const user = filteredUsers[userIndex];

      return { value: user.id, label: user.name };
    });
  };

  usersCanBeChanged = (creationBookingsArray) => {
    const dates = [
      ...new Set(
        creationBookingsArray.map((booking) => {
          return booking[1];
        })
      ),
    ];

    const userIndexes = [
      ...new Set(
        creationBookingsArray.map((booking) => {
          return booking[0];
        })
      ),
    ];

    let result = true;

    dates.forEach((date) => {
      userIndexes.forEach((userIndex) => {
        const stringDate = DateTime.fromJSDate(date).startOf("day").toISODate();

        if (
          !creationBookingsArray.find(
            (booking) => DateTime.fromJSDate(booking[1]).toISODate() === stringDate && booking[0] === userIndex
          )
        ) {
          result = false;
        }
      });
    });

    return result;
  };

  changeCreationUsers = (
    creationBookingsArray,
    users,
    createBookingsRef,
    orderedPlannerUsers,
    setCreationBookingsArray
  ) => {
    if (users.length === 0) {
      alert("There should be at least 1 user selected");
      return;
    }

    new Set(
      creationBookingsArray.map((booking) => {
        return booking[0];
      })
    ).forEach((userIndex) => {
      createBookingsRef.current[userIndex]?.updateBookings([]);
    });

    const dates = [
      ...new Set(
        creationBookingsArray.map((booking) => {
          return DateTime.fromJSDate(booking[1]).startOf("day").toISODate();
        })
      ),
    ];

    const userIndexes = users.map((user) => {
      return orderedPlannerUsers.findIndex((u) => u.id === user.value);
    });

    const newBookings = userIndexes
      .map((userIndex) => {
        const userBookings = dates.map((date) => {
          return [userIndex, DateTime.fromISO(date).toJSDate()];
        });

        createBookingsRef.current[userIndex]?.updateBookings(userBookings);

        return userBookings;
      })
      .flat();

    useBookingsCreatingStore.getState().setBookings(newBookings);
    setCreationBookingsArray(newBookings);
  };

  selectTimeOffProjects = (
    setProjectsType,
    createBookingsRef,
    setDuration,
    projects,
    selectedProject,
    selectedTimeOff,
    setSelectedTimeOff,
    creationBookingsArray
  ) => {
    setProjectsType("time_off");
    const schedule = useAccountStore.getState().account.schedule;
    const date = createBookingsRef.current.filter((ref) => ref.bookings.length)?.[0]?.bookings?.[0]?.[1];
    let newDuration = 8;

    if (date) {
      newDuration = (schedule[date.getDay()][0] + schedule[date.getDay()][2]) / 60 || 4;
    }

    setDuration(newDuration);

    new Set(
      creationBookingsArray.map((booking) => {
        return booking[0];
      })
    ).forEach((userIndex) => {
      createBookingsRef.current[userIndex].updateDuration(newDuration);
    });

    let projectToSelect = projects.filter((project) => project.active && project.kind === "time_off")[0];

    if (selectedTimeOff === null || selectedProject.kind !== "time_off") {
      setSelectedTimeOff(projectToSelect);
    }
  };
}

export default CreateActions;
