import PlannerActions from "./PlannerActions";
import {
  useBookingsCreatingStore,
  useBookingsStore,
  useCopyBookingsStore,
  useDateStore,
  useShiftPressedStore,
  useZoomStore,
} from "../../../../stores/planner";
import $ from "jquery";
import Api from "../../../../Api";
import { getCellWidthForZoom, getNumberOfDays, OffsetDays, ToDateString } from "../../../../lib/DateUtils";
import { usersMaxScheduleTime } from "../../../../lib/PlannerUtils";
import { useAccountStore } from "../../../../stores/accountStore";

class UserPlannerActions extends PlannerActions {
  mouseUped(
    offsetX,
    offsetY,
    users,
    e,
    multipleSelectRef,
    selectBooking,
    plannerDispatcher,
    weekendsHidden,
    bookingFormat,
    createBookingsRef,
    usersRef,
    teams,
    teamId,
    closeEditWindow,
    openCreationWindow
  ) {
    if (useShiftPressedStore.getState().shiftPressed || useShiftPressedStore.getState().startSelected) {
      if (multipleSelectRef.current) {
        multipleSelectRef.current.setOpen(false);
        multipleSelectRef.current.setPos({ x: 0, y: 0 });
        multipleSelectRef.current.setSize({ width: 0, height: 0 });
      }

      const clientY = e?.clientY - 209 || 0;

      const bookingsPosition = Array.from($(".booking")).map((b) => {
        return {
          left: $(b).offset().left + $("#planner-scroll-list").scrollLeft() - 155,
          right: $(b).offset().left + $("#planner-scroll-list").scrollLeft() + $(`#${b.id}`).width() - 155,
          top: $(b).offset().top + $("#planner-scroll-list").scrollTop() - 209,
          id: b.id,
          bottom: $(b).offset().top + $(b).height() + $("#planner-scroll-list").scrollTop() - 209,
        };
      });

      bookingsPosition.forEach((booking) => {
        if (
          ((booking.left >= useShiftPressedStore.getState().startX &&
            booking.left <= offsetX + $("#planner-scroll-list").scrollLeft()) ||
            (booking.right >= useShiftPressedStore.getState().startX &&
              booking.right <= offsetX + $("#planner-scroll-list").scrollLeft())) &&
          ((booking.top >= useShiftPressedStore.getState().startY &&
            booking.top <= clientY + $("#planner-scroll-list").scrollTop()) ||
            (booking.bottom >= useShiftPressedStore.getState().startY &&
              booking.bottom <= clientY + $("#planner-scroll-list").scrollTop()))
        ) {
          selectBooking(this.getBookingById(+booking.id.split("-")[1]));
        }
      });
    }

    if (useCopyBookingsStore.getState().copyModeActive && useCopyBookingsStore.getState().copyBookingIds.length > 0) {
      let new_bookings = useBookingsStore.getState().bookings;

      if (this.clickStartX === offsetX && this.clickStartY === offsetY) {
        Api.Bookings.copy(
          useCopyBookingsStore.getState().copyBookingIds,
          users[this.userIndexStart].id,
          ToDateString(this.userDateStart)
        ).then((response) => {
          useBookingsStore.getState().setBookings([...new_bookings, ...response.data]);

          plannerDispatcher.afterCreationBookingAssignment(
            [this.userIndexStart],
            usersRef,
            teams,
            teamId,
            useBookingsStore.getState().bookings
          );

          this.userIndexStart = null;
          this.userDateStart = null;
        });
      }
    } else {
      if (
        this.clickStartX === offsetX &&
        this.clickStartY === offsetY &&
        e.button === 0 &&
        !useCopyBookingsStore.getState().copyModeActive
      ) {
        if (!this.userDateStart) {
          this.calculateUserDateStart(offsetX, offsetY, users);
        }

        if (!this.userDateStart) return;

        const isWeekend =
          (weekendsHidden && this.userDateStart.getDay() === 0) ||
          (weekendsHidden && this.userDateStart.getDay() === 6);

        const isCreatedInThisDate = createBookingsRef.current[this.userIndexStart]?.bookings?.some(
          (b) => b[0] === this.userIndexStart && b[1].getTime() === this.userDateStart.getTime()
        );

        if (!isCreatedInThisDate && !isWeekend && createBookingsRef.current[this.userIndexStart]) {
          if (this.shiftCreation) {
            this.shiftCreation = false;

            createBookingsRef.current.forEach((bookingRef) => {
              bookingRef.updateBookings([]);
            });

            createBookingsRef.current[this.userIndexStart].updateBookings([[this.userIndexStart, this.userDateStart]]);

            useBookingsCreatingStore.getState().setBookings([this.userIndexStart, this.userDateStart]);
          } else {
            createBookingsRef.current[this.userIndexStart].updateBookings([
              ...createBookingsRef.current[this.userIndexStart].bookings,
              [this.userIndexStart, this.userDateStart],
            ]);

            useBookingsCreatingStore.getState().addBooking([this.userIndexStart, this.userDateStart]);
          }

          createBookingsRef.current[this.userIndexStart].updateDuration(
            useAccountStore.getState().account?.default_booking_duration / 60
          );

          createBookingsRef.current.forEach((p) => {
            p.updateDuration(useAccountStore.getState().account?.default_booking_duration / 60);
          });

          useBookingsCreatingStore
            .getState()
            .setDuration(useAccountStore.getState().account?.default_booking_duration / 60);

          closeEditWindow();

          openCreationWindow();
        }
      } else {
        if (this.userIndexStart !== null && e.button === 0 && !e.ctrlKey) {
          useBookingsCreatingStore
            .getState()
            .setDuration(createBookingsRef?.current.find((ref) => ref.bookings.length > 0)?.duration);

          openCreationWindow();
        }
      }

      this.userIndexStart = null;
      this.userDateStart = null;
    }

    useShiftPressedStore.getState().setStartSelected(false);
    useShiftPressedStore.getState().setShiftPressed(false);

    super.mouseUped(offsetX, offsetY, users, e);
  }

  mouseMoved(offsetX, offsetY, users, weekendsHidden, multipleSelectRef, bookingFormat, createBookingsRef) {
    if (useShiftPressedStore.getState().shiftPressed || useShiftPressedStore.getState().startSelected) {
      let _height = 0;
      const userHeights = users.map(
        (user) => (usersMaxScheduleTime(user) / 60) * this.formatMapping[bookingFormat] + 17
      );

      userHeights.forEach((userHeight, index) => {
        if (
          _height < offsetY + $("#planner-scroll-list").scrollTop() &&
          _height + userHeight > offsetY + $("#planner-scroll-list").scrollTop()
        ) {
          this.userIndexEnd = index;

          const weekendWidth = weekendsHidden ? 10 : getCellWidthForZoom(useZoomStore.getState().zoom);
          const dayWidth = getCellWidthForZoom(useZoomStore.getState().zoom);

          multipleSelectRef.current.setPos({
            x: useShiftPressedStore.getState().startX,
            y: useShiftPressedStore.getState().startY,
          });

          let calculatedOffsetX = 0;
          let dateIteration = 0;

          while (calculatedOffsetX < offsetX + $("#planner-scroll-list").scrollLeft()) {
            calculatedOffsetX += dateIteration % 7 < 5 ? dayWidth : weekendWidth;
            dateIteration++;
          }

          multipleSelectRef.current.setSize({
            width: offsetX + $("#planner-scroll-list").scrollLeft() - useShiftPressedStore.getState().startX,
            height: offsetY + $("#planner-scroll-list").scrollTop() - useShiftPressedStore.getState().startY,
          });

          this.userDateEnd = OffsetDays(useDateStore.getState().date, dateIteration - 1);
        }

        _height += userHeight;
      });
    } else {
      if (this.userIndexStart === null || useCopyBookingsStore.getState().copyModeActive) {
        return null;
      }

      multipleSelectRef.current.setPos({ x: 0, y: 0 });
      multipleSelectRef.current.setSize({ width: 0, height: 0 });

      const weekendWidth = weekendsHidden ? 10 : getCellWidthForZoom(useZoomStore.getState().zoom);
      const dayWidth = getCellWidthForZoom(useZoomStore.getState().zoom);
      let _height = 0;
      const userHeights = users.map(
        (user) => (usersMaxScheduleTime(user) / 60) * this.formatMapping[bookingFormat] + 17
      );

      userHeights.forEach((userHeight, index) => {
        if (
          _height < offsetY + $("#planner-scroll-list").scrollTop() &&
          _height + userHeight > offsetY + $("#planner-scroll-list").scrollTop()
        ) {
          this.userIndexEnd = index;

          let calculatedOffsetX = 0;
          let dateIteration = 0;

          while (calculatedOffsetX < offsetX + $("#planner-scroll-list").scrollLeft()) {
            calculatedOffsetX += dateIteration % 7 < 5 ? dayWidth : weekendWidth;
            dateIteration++;
          }

          this.userDateEnd = OffsetDays(useDateStore.getState().date, dateIteration - 1);
        }

        _height += userHeight;
      });

      let currentHeight = 0;

      for (let i = 0; i < this.userIndexEnd; i++) {
        currentHeight += userHeights[i];
      }

      let currentBookingsHeight = 0;

      let currentBookings =
        useBookingsStore.getState().bookings?.filter((booking) => {
          return (
            booking.user_id === users[this.userIndexEnd].id &&
            booking.date === ToDateString(this.userDateEnd) &&
            booking.deleted_at === null
          );
        }) || [];

      currentBookings.forEach((booking) => {
        currentBookingsHeight += (booking.duration / 60) * this.formatMapping[bookingFormat];
      });

      let newDuration =
        (offsetY + $("#planner-scroll-list").scrollTop() - currentHeight - currentBookingsHeight) /
        this.formatMapping[bookingFormat];

      newDuration = +(Math.round(newDuration * 2) / 2).toFixed(2);

      createBookingsRef.current.forEach((bookingCreateRef) => {
        if (this.userIndexStart !== this.userIndexEnd || getNumberOfDays(this.userDateStart, this.userDateEnd) > 0) {
          bookingCreateRef?.updateBookings(this.redrawCreationBookings(weekendsHidden));
          bookingCreateRef?.updateDuration(newDuration);
        } else {
          if (
            useBookingsCreatingStore
              .getState()
              .bookingsCreating.findIndex((b) => b[0] === this.userIndexStart && b[1] === this.userDateStart) === -1
          ) {
            createBookingsRef.current[this.userIndexStart].updateBookings([
              ...createBookingsRef.current[this.userIndexStart].bookings.filter(
                (b) => b[0] !== this.userIndexStart || b[1] !== this.userDateStart
              ),
              [this.userIndexStart, this.userDateStart],
            ]);
          }

          createBookingsRef.current.forEach((p) => {
            p.updateDuration(newDuration);
          });
        }
      });
    }
  }

  mouseDowned(offsetX, offsetY, users, multipleSelectRef, weekendsHidden, bookingFormat) {
    if (useShiftPressedStore.getState().shiftPressed) {
      useShiftPressedStore.getState().setStartSelected(true);
      multipleSelectRef.current.setOpen(false);
      multipleSelectRef.current.setPos({ x: 0, y: 0 });
      multipleSelectRef.current.setSize({ width: 0, height: 0 });

      useShiftPressedStore.getState().setStartX(offsetX + $("#planner-scroll-list").scrollLeft());
      useShiftPressedStore.getState().setStartY(offsetY + $("#planner-scroll-list").scrollTop());

      multipleSelectRef.current.setPos({
        x: offsetX + $("#planner-scroll-list").scrollLeft(),
        y: offsetY + $("#planner-scroll-list").scrollTop(),
      });

      multipleSelectRef.current.setOpen(true);
    }

    this.clickStartX = offsetX;
    this.clickStartY = offsetY;

    const weekendWidth = weekendsHidden ? 10 : getCellWidthForZoom(useZoomStore.getState().zoom);
    const dayWidth = getCellWidthForZoom(useZoomStore.getState().zoom);
    let currentHeight = 0;
    const userHeights = users.map((user) => (usersMaxScheduleTime(user) / 60) * this.formatMapping[bookingFormat] + 17);

    userHeights.forEach((userHeight, index) => {
      if (
        currentHeight < offsetY + $("#planner-scroll-list").scrollTop() &&
        currentHeight + userHeight > offsetY + $("#planner-scroll-list").scrollTop()
      ) {
        this.userIndexStart = index;

        let calculatedOffsetX = 0;
        let dateIteration = 0;

        while (calculatedOffsetX < offsetX + $("#planner-scroll-list").scrollLeft()) {
          calculatedOffsetX += dateIteration % 7 < 5 ? dayWidth : weekendWidth;
          dateIteration++;
        }

        this.userDateStart = OffsetDays(useDateStore.getState().date, dateIteration - 1);
      }

      currentHeight += userHeight;
    });
  }
}

export default UserPlannerActions;
