import React, { useEffect, useState } from "react";
import Api from "../../Api";
import Smartlook from "smartlook-client";
import CustomTranslationEngine from "../../locales/CustomTranslationEngine";
import { useProfileStore } from "../../stores/profileStore";
import { shallow } from "zustand/shallow";

const Middleware = () => {
  const [profile] = useProfileStore((state) => [state.profile], shallow);

  useEffect(() => {
    updateTranslations();
    startSmartlook();
  }, [profile]);

  const startSmartlook = () => {
    let creationDate, userId, userEmail;

    // if (profile) {
    //   creationDate = profile.created_at;
    //
    //   const today = new Date();
    //   const created_at = new Date(creationDate);
    //   const daysFromCreation = Math.floor((today - created_at) / 24 / 60 / 60 / 1000);
    // }

    if (process.env.REACT_APP_ENV === "production" || process.env.REACT_APP_ENV === "sandbox") {
      Smartlook.init("96e34d02b515c91e65ebd2042c839315773d6c0d");
      Smartlook.record({ numbers: true, emails: true });

      if (profile) {
        userId = profile.id;
        userEmail = profile.email;
        creationDate = profile.created_at;

        const today = new Date();
        const created_at = new Date(creationDate);
        const daysFromCreation = Math.floor((today - created_at) / 24 / 60 / 60 / 1000);

        if (daysFromCreation < 15) {
          Smartlook.init("96e34d02b515c91e65ebd2042c839315773d6c0d");
          Smartlook.record({ numbers: true, emails: true });

          Smartlook.identify(userId.toString(), {
            email: userEmail,
          });
        }
      } else {
        Smartlook.init("96e34d02b515c91e65ebd2042c839315773d6c0d");
        Smartlook.record({ numbers: true, emails: true });
      }
    }
  };

  const updateTranslations = () => {
    if (localStorage.getItem("Auth-Token")) {
      Api.Account.get().then((response) => {
        const account = response.data;

        new CustomTranslationEngine(account).updateTranslationValues();
      });
    } else {
      setTimeout(() => {
        updateTranslations();
      }, 1000);
    }
  };

  return <div id="intercom" />;
};

export default Middleware;
