import { shallow } from "zustand/shallow";
import { useAccountStore } from "../../../../stores/accountStore";
import { InfoTooltip } from "../../../default_components/InfoTooltip";
import { isoCodes } from "../../../default_values/defaultDateFormats";
import React from "react";
import { useTranslation } from "react-i18next";

const linkToAnchor = (text) => {
  let urlRegex = /(?:(?:https?|ftp):\/\/)?[\w/\-?=%.]+\.[\w/\-&?=%.]+/gi;
  return (text = text.replace(
    urlRegex,
    (url) => '<a rel="noopener noreferrer" href="http://' + url + '">' + url + "</a>"
  ));
};

const dateOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

const GeneralContent = ({ project, projectsData, users }) => {
  const { t } = useTranslation();
  const [account] = useAccountStore((state) => [state.account], shallow);

  return (
    <div style={{ display: "flex", gap: 20 }}>
      <div className="project-form__left-side">
        <div className="project-form__project-color" style={{ backgroundColor: project.color }} />
      </div>
      <div className="project-form__right-side">
        {projectsData.map((rowData) => (
          <div className="project-form__row">
            {rowData.map((data) => (
              <div className="project-form__field">
                <p className="project-form__field-name">{data.title}</p>
                <p className="project-form__field-value">
                  {data.value}

                  {data?.hover_data && (
                    <div className="project-form__hidden-hover">
                      {data.hover_data.map((data) => (
                        <p>{data.name}</p>
                      ))}
                    </div>
                  )}
                </p>
              </div>
            ))}
          </div>
        ))}

        {project.kind !== "time_off" && (
          <div className="project-form__row" style={{ height: "unset" }}>
            <div className="project-form__row_notes">
              <p className="project-form__field-name">{t("projects.notes")}</p>

              <div className="project-form__notes">
                <span
                  style={{
                    wordBreak: "break-word",
                    textAlign: "left",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: linkToAnchor(project.notes || "<p>-</p>") || "<p>-</p>",
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <InfoTooltip
        style={{ position: "absolute", left: 40, bottom: 50 }}
        content={
          <div className="project-form__creation-info" style={{ flexDirection: "column" }}>
            <div>
              <p>
                <i style={{ marginRight: 4 }}>{t("reference_id")}: </i> {project.reference_id}
              </p>
            </div>

            {project.created_by && (
              <div>
                <p>
                  <i> {t("created_by")}</i>

                  <b>{users.find(({ id }) => id === project.created_by)?.name || "System"}</b>
                  {" at " + new Date(project.created_at).toLocaleDateString(isoCodes(account.date_format), dateOptions)}
                </p>
              </div>
            )}
            {project.updated_by && (
              <div>
                <p>
                  <i>{t("updated_by")}</i>
                  <b>{users.find(({ id }) => id === project.updated_by)?.name || "System"}</b>
                  {" at " + new Date(project.updated_at).toLocaleDateString(isoCodes(account.date_format), dateOptions)}
                </p>
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};

export default GeneralContent;
