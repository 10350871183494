import React, { useContext, useRef, useState, useMemo } from "react";
import { getNumberOfDays, getCellWidthForZoom, OffsetDays, ToDateString } from "../../../lib/DateUtils";
import { defaultProjectIcons, plannerIcons } from "../../default_values/DefaultProjectIcons";
import BlockIcon from "@mui/icons-material/Block";
import moment from "moment";
import {
  useBookingsEditingStore,
  useCopyBookingsStore,
  useShiftPressedStore,
  useFilteredUsersStore,
  useZoomStore,
} from "../../../stores/planner";
import { PlannerContext } from "../context/PlannerContext";
import lsKeys from "../../default_values/defaultKeys";
import { DraggableBooking } from "./DraggableBooking";
import { DAY_VIEW_HEIGHT, DAY_VIEW_WIDTH, getPlannerOffsetX, getPlannerOffsetY } from "../../../lib/PlannerUtils";
import { DateTime } from "luxon";
import { useProfileStore } from "../../../stores/profileStore";
import { shallow } from "zustand/shallow";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import { bookingStartTimeText } from "./planner_right_side_modal/StartTimeBlock";

const formatMapping = {
  0: 5,
  1: 10,
  2: 15,
};

const UserBookings = ({
  bookingsArray,
  date,
  topOffset,
  weekendsHidden,
  maxHeight,
  selectBooking,
  activeProjects,
  changeTooltip,
  bookingFormat,
  teams,
  changeContextMenu,
  updateBookingsRequest,
  user,
  mouseMoved,
  mouseUped,
  mouseDowned,
  tasks,
  weekendInfoRef,
  index,
}) => {
  const bookingIds = useBookingsEditingStore(({ bookingsEditing }) => bookingsEditing.map((b) => b.id));

  const [currentUser] = useProfileStore((state) => [state.profile], shallow);

  const { team, projects } = useContext(PlannerContext);
  const role = localStorage.getItem(lsKeys.ROLE);
  const [zoom] = useZoomStore((state) => [state.zoom], shallow);

  const [initialSize, setInitialSize] = useState(null);
  const [initialPos, setInitialPos] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const bookingRef = useRef([]);

  const openWeekendInfo = (e, weekendDate, userId) => {
    weekendInfoRef.current.setPos({
      x: e.pageX,
      y: e.pageY,
    });

    weekendInfoRef.current.setOpen(true);
    weekendInfoRef.current.setUserIndex(index);
    weekendInfoRef.current.setUserId(userId);

    if (new Date(weekendDate).getDay() === 0) {
      weekendInfoRef.current.setDates([
        DateTime.fromISO(weekendDate).minus({ day: 1 }).toISODate(),
        DateTime.fromISO(weekendDate).toISODate(),
      ]);
    } else {
      weekendInfoRef.current.setDates([
        DateTime.fromISO(weekendDate).toISODate(),
        DateTime.fromISO(weekendDate).plus({ day: 1 }).toISODate(),
      ]);
    }
  };

  const numRound = (num, precision) => {
    return Math.round(num / precision) * precision;
  };

  const checkSelect = (booking) => bookingIds.includes(booking.id);

  const startBookingResizing = (e, booking) => {
    e.stopPropagation();
    setIsDragging(true);

    let resizable = document.getElementById(`booking-${booking.id}`);

    setInitialPos(e.clientY);
    setInitialSize(resizable.offsetHeight);
  };

  const resizingBooking = (e, booking) => {
    e.stopPropagation();
    let resizable = document.getElementById(`booking-${booking.id}`);

    let newHeight =
      parseFloat(initialSize) + numRound(parseFloat(e.clientY - initialPos), formatMapping[bookingFormat] / 2);

    if (newHeight >= formatMapping[bookingFormat] / 2) {
      resizable.style.height = `${numRound(
        calculateHeight((newHeight / formatMapping[bookingFormat]) * 60, booking.id, booking.date),
        formatMapping[bookingFormat] / 2
      )}px`;
      resizable.style.zIndex = 2;

      if (resizable.querySelector(".booking__project-duration")) {
        resizable.querySelector(".booking__project-duration").innerHTML =
          parseFloat(document.getElementById(`booking-${booking.id}`).style.height) / formatMapping[bookingFormat] +
          "h";
      }
    }
  };

  const endBookingResizing = (e, booking) => {
    e.stopPropagation();

    let resizable = document.getElementById(`booking-${booking.id}`);
    resizable.style.zIndex = 1;

    let newDuration =
      numRound(parseFloat(resizable.style.height), formatMapping[bookingFormat] / 2) / formatMapping[bookingFormat];

    updateBookingsRequest(
      newDuration,
      booking.tentative,
      booking.location,
      booking.start_time,
      booking.comment,
      booking.using_budget,
      [booking.id],
      getProject(booking.project_id),
      booking.task_id
    );

    setIsDragging(false);
  };

  const isResizable = (booking) => {
    if (!bookingIds.length) {
      return true;
    } else {
      return bookingIds.length === 1 && bookingIds[0] === booking.id;
    }
  };

  const calculateLeftOffset = (bookingDate) => {
    let dateOffset = getNumberOfDays(date, bookingDate);
    let finalOffset = 0;
    const initialWeekDay = date.getDay();

    for (let i = 0; i < dateOffset; i++) {
      const weekday = (initialWeekDay + i) % 7;
      const isWeekend = weekendsHidden && (weekday === 0 || weekday === 6);

      if (i + 1 === dateOffset && weekendsHidden && weekday === 6) break;

      finalOffset += isWeekend ? 10 : getCellWidthForZoom(zoom);
    }

    return finalOffset;
  };

  const calculateWidth = (duration, date) => {
    if (zoom === 1) {
      return (duration / 60) * DAY_VIEW_WIDTH;
    } else {
      if (DateTime.fromISO(date).weekday === 5) {
        return getCellWidthForZoom(zoom);
      } else {
        return getCellWidthForZoom(zoom) - 1;
      }
    }
  };

  const calculateHeight = (bookingDuration, bookingId, bookingDate) => {
    if (zoom === 1) {
      return DAY_VIEW_HEIGHT;
    }

    let sameDateBookings = getSameDateBookings(bookingDate);

    let index = sameDateBookings.findIndex((booking) => booking.id === bookingId);
    let emptySpace = maxHeight;

    for (let i = 0; i < index; i++) {
      emptySpace -= (sameDateBookings[i].duration / 60) * formatMapping[bookingFormat];
    }

    emptySpace = emptySpace > 0 ? emptySpace : 0;

    let calculatedHeight = (bookingDuration / 60) * formatMapping[bookingFormat];

    return calculatedHeight <= emptySpace ? calculatedHeight : emptySpace;
  };

  const calculateOffsetLeft = (id, date, start_time) => {
    if (zoom === 1) {
      let offset = DAY_VIEW_WIDTH;
      let sameDateBookings = getSameDateBookings(date);

      let index = sameDateBookings.filter((b) => !b.start_time).findIndex((booking) => booking.id === id);

      for (let i = 0; i < index; i++) {
        offset += calculateWidth(sameDateBookings[i].duration, sameDateBookings[i].date);
      }

      if (start_time) {
        return ((start_time - 700) / 100) * DAY_VIEW_WIDTH;
      }

      return offset;
    } else {
      return 0;
    }
  };

  const calculateOffsetTop = (id, date, order) => {
    if (zoom === 1) {
      return topOffset;
    }

    let offset = 0;
    let sameDateBookings = getSameDateBookings(date);

    let index = sameDateBookings.findIndex((booking) => booking.id === id);

    for (let i = 0; i < index; i++) {
      offset += calculateHeight(sameDateBookings[i].duration);
    }

    return topOffset + offset;
  };

  const calculateNewTooltipX = (booking, e, i) => {
    return bookingRef.current[i].getBoundingClientRect().left + getCellWidthForZoom(zoom) / 2 + 7;
  };

  const calculateNewTooltipY = (booking, e, i) => {
    return bookingRef.current[i].getBoundingClientRect().top + 140;
  };

  const getSameDateBookings = (date) => {
    let datedBookings = bookingsArray
      .filter((booking) => booking.date === date)
      .filter((booking) => booking.deleted_at === null);

    let startTimeBookings = datedBookings
      .filter((booking) => booking.start_time !== null)
      .sort((a, b) => {
        return a.start_time - b.start_time;
      });
    let otherBookings = datedBookings
      .filter((booking) => booking.start_time === null)
      .sort((a, b) => {
        return a.order - b.order;
      });

    return [...startTimeBookings, ...otherBookings];
  };

  const project_code = (booking) => {
    if (team.id === booking.team_id) {
      return getProject(booking.project_id)?.code?.slice(0, 5);
    } else {
      return <TeambookIcon name={icons.BOOKING_BLOCK} color="alternative_default" />;
    }
  };

  const bookingIsNotTimeOff = (booking) => {
    return getProject(booking.project_id)?.kind !== "time_off";
  };

  const getBackground = (booking) => {
    if (team.id === booking.team_id) {
      return `${booking.tentative ? "var(--background-1)" : booking.project_color}`;
    } else {
      return "#9D9D9D";
    }
  };

  const getProject = (id) => {
    if (activeProjects.find((pr) => pr.id === id) === undefined) {
      return projects.find((pr) => pr.id === id);
    }

    return activeProjects.find((pr) => pr.id === id);
  };

  const getColor = () => {
    const isDarkTheme = localStorage.getItem(lsKeys.THEME) === "dark";

    if (isDarkTheme) {
      return "#FFFFFF";
    } else {
      return "#474747";
    }
  };

  const getBorder = (booking) => {
    if (team.id === booking.team_id) {
      return booking.tentative ? `2px solid ${booking.project_color}` : "";
    } else {
      return "none";
    }
  };

  const getLineColor = (booking) => {
    if (team.id === booking.team_id) {
      return `${booking.project_color}`;
    } else {
      return "#9D9D9D";
    }
  };

  const getOpacity = (bookingDate) => {
    return new Date() - 86400000 > new Date(bookingDate) ? "0.7" : "1";
  };

  const dateIsNotWeekend = (date) => {
    if (!weekendsHidden || !date || zoom === 1) {
      return true;
    }

    return moment(date).day() !== 0 && moment(date).day() !== 6;
  };

  const bookingObjectStyles = (booking) => {
    return {
      width: `${calculateWidth(booking.duration, booking.date)}px`,
      height: `${calculateHeight(booking.duration, booking.id, booking.date)}px`,
      left: `${calculateOffsetLeft(booking.id, booking.date, booking.start_time)}px`,
      top: 0,
      backgroundColor: `${getBackground(booking)}66`,
      border: getBorder(booking),
      color: getColor(booking),
      fontWeight: "700",
      display:
        calculateHeight(booking.duration, booking.id, booking.date) === 0 || booking.deleted_at !== null
          ? "none"
          : "block",
      opacity: getOpacity(booking.date),
      padding: calculateBookingPadding(booking),
    };
  };

  const calculateBookingPadding = (booking) => {
    if (zoom >= 90) {
      return "1px 0px 0px 0px";
    } else {
      if (booking.tentative) {
        return "2px 2px 2px 6px";
      } else {
        return checkSelect(booking) ? "0px 0px 0px 4px" : "2px 2px 2px 6px";
      }
    }
  };

  if (bookingsArray.length === 0) {
    return null;
  }

  return (
    <>
      {bookingsArray
        .filter((b) => zoom !== 1 || b.date === ToDateString(date))
        .map((booking, i) =>
          dateIsNotWeekend(booking.date) ? (
            <DraggableBooking
              isDragging={
                ["regular", "contractor"].includes(role) ||
                (role === "self_planner" && booking.user_id !== currentUser.id)
                  ? true
                  : isDragging
              }
              key={"drag" + booking.id + "/" + booking.duration}
              booking={booking}
              canDrag={team.id === booking.team_id}
            >
              <div
                key={`booking-${booking.id}`}
                id={`booking-${booking.id}`}
                aria-label="booking"
                className={`proj-${booking.project_id} booking`}
                data-active={bookingIds.includes(booking.id)}
                style={{
                  ...bookingObjectStyles(booking),
                  transform: `translate3d(${calculateLeftOffset(booking.date)}px, ${calculateOffsetTop(
                    booking.id,
                    booking.date,
                    booking.order
                  )}px, 0)`,
                }}
                onClick={() => {
                  selectBooking(booking);
                }}
                onMouseLeave={() => {
                  changeTooltip({ open: false });
                }}
                onMouseDown={(e) => {
                  if (
                    useShiftPressedStore.getState().shiftPressed ||
                    (useCopyBookingsStore.getState().copyModeActive &&
                      useCopyBookingsStore.getState().copyBookingIds.length > 0)
                  ) {
                    e.preventDefault();
                    mouseDowned(
                      getPlannerOffsetX(e),
                      getPlannerOffsetY(e, zoom),
                      useFilteredUsersStore.getState().filteredUsers
                    );
                  }
                }}
                onMouseUp={(e) => {
                  if (
                    useShiftPressedStore.getState().shiftPressed ||
                    useCopyBookingsStore.getState().copyBookingIds.length > 0
                  ) {
                    mouseUped(
                      getPlannerOffsetX(e),
                      getPlannerOffsetY(e),
                      useFilteredUsersStore.getState().filteredUsers,
                      e
                    );
                  }
                }}
                onMouseMove={(e) => {
                  if (useShiftPressedStore.getState().shiftPressed) {
                    mouseMoved(
                      getPlannerOffsetX(e),
                      getPlannerOffsetY(e),
                      useFilteredUsersStore.getState().filteredUsers,
                      e
                    );
                  }

                  changeTooltip({
                    open: true,
                    projectCode: getProject(booking.project_id)?.code,
                    projectName: getProject(booking.project_id)?.name,
                    clientName: getProject(booking.project_id)?.client_name,
                    bookingDuration: booking.duration,
                    comment: booking.comment,
                    positionX: calculateNewTooltipX(booking, e, i),
                    positionY: calculateNewTooltipY(booking, e, i),
                    bookingId: booking.id,
                    team: teams.find((team) => team.id === booking.team_id),
                    task: tasks.filter((t) => t.id === booking.task_id)?.[0],
                  });
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  if (
                    ["admin", "planner"].includes(role) ||
                    (["self_planner"].includes(role) && currentUser.id === booking.user_id)
                  ) {
                    changeTooltip({
                      open: false,
                    });
                    changeContextMenu({
                      open: true,
                      posY: e.pageY,
                      posX: e.pageX,
                      booking: booking,
                    });
                  }

                  e.stopPropagation();
                }}
                ref={(el) => (bookingRef.current[i] = el)}
              >
                {!booking.tentative && (
                  <div
                    className="booking-line"
                    style={{
                      backgroundColor: getLineColor(booking),
                      width:
                        zoom === 90 ? (checkSelect(booking) ? "1px" : "2px") : checkSelect(booking) ? "2px" : "4px",
                    }}
                  />
                )}

                {booking.duration > 60 &&
                  !(role === "self_planner" && user.id !== currentUser.id) &&
                  !["regular", "contractor"].includes(role) && (
                    <div
                      id={`resizer-${booking.id}`}
                      style={{
                        position: "absolute",
                        width: "100%",
                        bottom: -5,
                        height: 12,
                        zIndex: "10",
                        background: "transparent",
                        cursor: "row-resize",
                        display: isResizable(booking) ? "block" : "none",
                      }}
                      onDragStart={(e) => startBookingResizing(e, booking)}
                      onDrag={(e) => resizingBooking(e, booking)}
                      onDragEnd={(e) => endBookingResizing(e, booking)}
                      draggable={true}
                    />
                  )}

                {zoom < 90 && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        height: "17px",
                      }}
                    >
                      {zoom === 14 ? (
                        <p className="booking__project-name">
                          {team.id === booking.team_id ? (
                            getProject(booking.project_id)?.name
                          ) : (
                            <TeambookIcon name={icons.BOOKING_BLOCK} color="alternative_default" />
                          )}
                        </p>
                      ) : (
                        <p className="booking__project-code">{project_code(booking)}</p>
                      )}
                    </div>

                    {booking.task_id && (
                      <div
                        style={{
                          height: "17px",
                        }}
                      >
                        <p className="booking__task-name">{tasks.filter((t) => t.id === booking.task_id)?.[0]?.name}</p>
                      </div>
                    )}
                  </>
                )}

                {getProject(booking.project_id) && (
                  <>
                    <div
                      style={{
                        gap: zoom >= 90 && "2px",
                        flexDirection: zoom >= 90 ? "column" : "row",
                      }}
                      className="booking__booking-icon"
                    >
                      {getProject(booking.project_id)?.kind === "time_off" &&
                        plannerIcons(getProject(booking.project_id).icon_id, getColor(), zoom)}

                      {booking.repetition && (
                        <TeambookIcon name={icons.BOOKING_REPETITION} color="alternative_default" />
                      )}

                      {booking.location === 1 && (
                        <TeambookIcon name={icons.BOOKING_ON_CLIENT_SITE} color="alternative_default" />
                      )}

                      {booking.comment.length > 0 && (
                        <TeambookIcon name={icons.BOOKING_COMMENT} color="alternative_default" />
                      )}

                      {booking.location === 2 && (
                        <TeambookIcon name={icons.BOOKING_AT_HOME} color="alternative_default" />
                      )}

                      {booking.using_budget === false && (
                        <TeambookIcon name={icons.BOOKING_NON_BILLABLE} color="alternative_default" />
                      )}
                    </div>

                    {booking.start_time?.toString() && (
                      <p
                        className="booking__start-time-block"
                        style={{
                          display: "flex",
                          justifyContent: zoom >= 90 && "center",
                        }}
                      >
                        {zoom < 90 && (
                          <p
                            className="booking__start-time-text"
                            style={{
                              color: getColor(booking),
                            }}
                          >
                            {bookingStartTimeText(booking)}
                          </p>
                        )}
                      </p>
                    )}
                  </>
                )}
              </div>
            </DraggableBooking>
          ) : (
            bookingIsNotTimeOff(booking) && (
              <TeambookIcon
                name={icons.WARNING}
                color="red"
                className="booking__weekend-booking-sign pointer"
                style={{
                  left: `${calculateLeftOffset(booking.date) + 3}px`,
                }}
                key={`bookings-weekend-${booking.id}`}
                onClick={(e) => {
                  openWeekendInfo(e, booking.date, booking.user_id);
                }}
              />
            )
          )
        )}
    </>
  );
};

export default UserBookings;
