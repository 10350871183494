import { create } from "zustand";
import { ToDateString } from "../lib/DateUtils";
import lsKeys from "../components/default_values/defaultKeys";

export const useBookingsEditingStore = create((set) => ({
  editWindowOpened: false,
  bookingsEditing: [],
  addBooking: (booking) =>
    set((state) => ({
      bookingsEditing: [...state.bookingsEditing.filter((b) => b.id !== booking.id), booking],
    })),
  clearBookings: () => set({ bookingsEditing: [] }),
  removeBooking: (id) =>
    set((state) => ({
      bookingsEditing: state.bookingsEditing.filter((booking) => booking.id !== id),
    })),
  setBookings: (bookings) => set({ bookingsEditing: bookings }),
  setEditWindowOpened: (state) => set({ editWindowOpened: state }),
}));
export const useShiftPressedStore = create((set) => ({
  shiftPressed: false,
  startSelected: false,
  setStartSelected: (isSelected) => set({ startSelected: isSelected }),
  setShiftPressed: (isPressed) => set({ shiftPressed: isPressed }),
  startX: 0,
  startY: 0,
  setStartX: (new_x) => set({ startX: new_x }),
  setStartY: (new_y) => set({ startY: new_y }),
}));

export const useBookingsCreatingStore = create((set) => ({
  createWindowOpened: false,
  dayViewBookings: [],
  setDayViewBookings: (bookings) => set({ dayViewBookings: bookings }),
  dayViewStartTime: 0,
  setDayViewStartTime: (value) => set({ dayViewStartTime: value }),
  bookingsCreating: [],
  bookingsDuration: 0,
  addBooking: (booking) =>
    set((state) => ({
      bookingsCreating: [...state.bookingsCreating, booking].filter(
        (value, index, self) =>
          index === self.findIndex((t) => t[0] === value[0] && t[1] && ToDateString(t[1]) === ToDateString(value[1]))
      ),
    })),
  clearBookings: () => set({ bookingsCreating: [], dayViewBookings: [] }),
  removeBooking: (index, date) =>
    set((state) => ({
      bookingsCreating: state.bookingsCreating.filter(
        (booking) => booking[0] !== index || ToDateString(booking[1]) !== ToDateString(date)
      ),
    })),
  setBookings: (bookings) => set({ bookingsCreating: bookings }),
  setDuration: (duration) => set({ bookingsDuration: duration || 0 }),
  setCreateWindowOpened: (state) => set({ createWindowOpened: state }),
}));

//TODO: merge project and timeoFf
export const useSelectedProjectStore = create((set) => ({
  projectsType: localStorage.getItem(lsKeys.SAVED_PROJECT_TYPE) || "default",
  selectedProject: JSON.parse(localStorage.getItem(lsKeys.SAVED_PROJECT)) || null,
  selectedTimeOff: JSON.parse(localStorage.getItem(lsKeys.SAVED_TIME_OFF)) || null,
  setProjectsType: (type) => set({ projectsType: type }),
  setSelectedProject: (project) => set({ selectedProject: project }),
  setSelectedTimeOff: (project) => set({ selectedTimeOff: project }),
}));

export const useZoomStore = create((set) => ({
  zoom: null,
  setZoom: (new_zoom) => set({ zoom: new_zoom }),
}));

export const useTeambookFilter = create((set) => ({
  filterValues: [],
  filterType: "Or",
  setFilterValue: (filterValues) => set({ filterValues: filterValues }),
  switchFilterType: () =>
    set((state) => ({
      filterType: state.filterType === "Or" ? "And" : "Or",
    })),
}));

export const useBookingsStore = create((set) => ({
  bookings: [],
  setBookings: (bookings) => set({ bookings: bookings }),
}));

export const useGroupedBookingsStore = create((set) => ({
  bookings: [],
  setBookings: (bookings) => set({ bookings: bookings }),
}));

export const useMoveBookingStore = create((set) => ({
  movingHash: {
    booking: null,
    from_user: null,
    to_user: null,
    from_date: null,
    to_date: null,
    offsetX: null,
    zoom: null,
    moveBooking: null,
  },
}));

export const useFilteredUsersStore = create((set) => ({
  filteredUsers: [],
  setFilteredUsers: (filteredUsers) => set({ filteredUsers: filteredUsers }),
}));

export const useCopyBookingsStore = create((set) => ({
  copyModeActive: false,
  copyBookingIds: [],
  setCopyModeActive: (value) => set({ copyModeActive: value }),
  setCopyBookingIds: (ids) => set({ copyBookingIds: ids }),
}));

export const useMilestonesStore = create((set) => ({
  creationOrder: null,
  setCreationOrder: (order) => set({ creationOrder: order }),

  milestoneProject: null,
  setMilestoneProject: (project) => set({ milestoneProject: project }),

  milestoneIndex: null,
  setMilestoneIndex: (index) => set({ milestoneIndex: index }),

  milestoneStartDate: null,
  setMilestoneStartDate: (date) => set({ milestoneStartDate: date }),

  milestoneEndDate: null,
  setMilestoneEndDate: (date) => set({ milestoneEndDate: date }),
}));

export const useMoveUserStore = create((set) => ({
  startUser: null,
  setStartUser: (value) => set({ startUser: value }),

  endUser: null,
  setEndUser: (value) => set({ endUser: value }),
}));

export const useDateStore = create((set) => ({
  date: undefined,
  setDate: (value) => set({ date: value }),
}));
