import React, { useEffect, useRef, useState } from "react";
import Api from "../../Api";
import { DateTime } from "luxon";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../header/Header";
import MyWeekControlBar from "./MyWeekControlBar";
import MyWeekContent from "./MyWeekContent";
import { useProfileStore } from "../../stores/profileStore";
import { shallow } from "zustand/shallow";
import { useAccountStore } from "../../stores/accountStore";
import DidYouKnow from "../planner/default_components/DidYouKnow";

export default ({ match, history }) => {
  const navigate = useNavigate();
  const { myWeekDate } = useParams();

  const [bookings, setBookings] = useState([]);
  const [projects, setProjects] = useState();
  const [date, setDate] = useState(DateTime.now() || DateTime.fromISO(match.params.date));
  const [tasks, setTasks] = useState([]);
  const [currentUser] = useProfileStore((state) => [state.profile], shallow);
  const [account] = useAccountStore((state) => [state.account], shallow);

  const didYouKnowRef = useRef({});

  useEffect(() => {
    if (myWeekDate === undefined) {
      navigate(`/my-week/${DateTime.now().toISODate()}`, {
        replace: true,
      });
    }

    const from = date.startOf("week");
    const to = from.plus({ days: 6 });

    getBookings(currentUser, from, to);
    getProjects();
    Api.Tasks.get().then((res) => setTasks(res.data));
  }, []);

  const getBookings = (user, from, to) => {
    Api.Bookings.get([user.id], from, to).then((bookings_res) => {
      setBookings(bookings_res.data);
    });
  };

  const getProjects = () => {
    Api.Projects.minimal().then((projects_res) => {
      setProjects(projects_res.data);
    });
  };

  const changeDate = (newDate) => {
    const from = newDate;
    const to = newDate.plus({ days: 6 });

    getBookings(currentUser, from, to);
    getProjects();

    setDate(newDate);
    navigate(`/my-week/${newDate.toISODate()}`, {
      replace: true,
    });
  };

  return (
    <>
      <div className="my-week-page">
        <Header didYouKnowRef={didYouKnowRef} />
        {currentUser && account && <MyWeekControlBar date={date} changeDate={changeDate} />}
        {currentUser && <DidYouKnow ref={didYouKnowRef} />}
        {date && bookings && projects && (
          <MyWeekContent date={date} bookings={bookings} projects={projects} tasks={tasks} />
        )}
      </div>
    </>
  );
};
