import React, { useState, useEffect, useRef } from "react";
import Api from "../../../Api";
import Header from "../../header/Header";
import PlannerControlBar from "../default_components/PlannerControlBar";
import NoMatch from "../../default_components/NoMatch";
import { useNavigate } from "react-router-dom";
import {
  GetWeekMonday,
  ToDateString,
  OffsetDays,
  GetStartDateForPlannerDate,
  getCellWidthForZoom,
} from "../../../lib/DateUtils";
import "../planner.scss";
import EditWindow from "../default_components/planner_right_side_modal/EditWindow";
import CreateWindow from "../default_components/planner_right_side_modal/CreateWindow";
import UserRow from "./components/UserRow";
import MoveBookingRequest from "../requests/MoveBookingRequest";
import CreateBookingsRequest from "../requests/CreateBookingsRequest";
import UpdateBookingsRequest from "../requests/UpdateBookingsRequest";
import AddUserDialog from "../modals/AddUserDialog";
import GetBookingsRequest from "../requests/GetBookingsRequest";
import filterUsers from "../lib/filterUsers";
import RepetitionDeleteQuestionDialog from "../modals/RepetitionDeleteQuestionDialog";
import OnboardingComponent from "../onboarding/OnboardingComponent";
import LoadingComponent from "../../default_components/LoadingComponent";
import PlannerDispatcher from "../lib/PlannerDispatcher";
import TeamChangedActions from "../lib/TeamChangedActions";
import RepetitionEditQuestionDialog from "../modals/RepetitionEditQuestionDialog";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import NoTeamsPlanner from "../../default_components/NoTeamsPlanner";
import RepetitionReachedModal from "../modals/RepetitionReachedModal";
import GetTeamsRequest from "../requests/GetTeamsRequest";
import ActionCable from "actioncable";
import GetActiveProjects from "../requests/GetActiveProjects";
import BookingTooltip from "../default_components/BookingTooltip";
import { _deleteBookingsRequest } from "../lib/plannerFunctions";
import {
  DAY_VIEW_HEIGHT,
  getPlannerOffsetX,
  getPlannerOffsetY,
  updateValueWithId,
  usersMaxScheduleTime,
  usersWorkingScheduleSum,
} from "../../../lib/PlannerUtils";
import DidYouKnow from "../default_components/DidYouKnow";
import MoveQuestionDialog from "../modals/MoveQuestionDialog";
import TooltipChanger from "../lib/TooltipChanger";
import PlanningBlocked from "../../default_components/PlanningBlocked";
import MultipleSelectHelp from "../default_components/MultipleSelectHelp";
import BookingRightClick from "../default_components/BookingRightClick";
import lsKeys from "../../default_values/defaultKeys";
import {
  useBookingsCreatingStore,
  useBookingsEditingStore,
  useBookingsStore,
  useCopyBookingsStore,
  useShiftPressedStore,
  useDateStore,
  useFilteredUsersStore,
  useMoveUserStore,
  useSelectedProjectStore,
  useTeambookFilter,
  useZoomStore,
} from "../../../stores/planner";
import socketHandler from "./socket/socketHandler";
import { PlannerContext } from "../context/PlannerContext";
import { shallow } from "zustand/shallow";
import $ from "jquery";
import NoBookingsFiltered from "../default_components/NoBookingsFiltered";
import { UserFeedbackBanner } from "../../default_components/UserFeedbackBanner";
import { SelectBookingsArea } from "./components/SelectBookingsArea";
import EditUser from "../../users/sub_components/EditUser/EditUser";
import DeleteUserFromTeamRequest from "../requests/DeleteUserFromTeamRequest";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import { WeekendInfo } from "../default_components/WeekendInfo";
import { useProfileStore } from "../../../stores/profileStore";
import { useAccountStore } from "../../../stores/accountStore";
import { useNotificationStore } from "../../../stores/notificationStore";
import PlannerRightClick from "../default_components/PlannerRightClick";
import { SwitchToPaidPlanBanner } from "../../default_components/SwitchToPaidPlanBanner";
import TeambookDialogYesNo from "../../default_components/TeambookDialogYesNo";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import UserPlannerActions from "../lib/basic_planner_actions/UserPlannerActions";
import UsersPlannerGrid from "./UsersPlannerGrid";

const CONTROL_KEY = 17;
const APPLE_META_KEY = 91;

const formatMapping = {
  0: 5,
  1: 10,
  2: 15,
};

const PersistedState = {
  setLastTeamId: (id) => {
    if (!parseInt(id)) {
      localStorage.removeItem(lsKeys.LAST_TEAMID_KEY);
    } else {
      localStorage.setItem(lsKeys.LAST_TEAMID_KEY, id);
    }
  },

  getAllTeamZoom: () => new Map(JSON.parse(localStorage.getItem(lsKeys.LAST_TEAMS_ZOOM_KEY))),

  saveAllTeamZoom: (map) => localStorage.setItem(lsKeys.LAST_TEAMS_ZOOM_KEY, JSON.stringify(Array.from(map))),

  getTeamZoom: (teamId) => PersistedState.getAllTeamZoom().get(teamId),

  setTeamZoom: (teamId, zoom) => {
    const m = PersistedState.getAllTeamZoom();

    m.set(teamId.toString(), parseInt(zoom));

    PersistedState.saveAllTeamZoom(m);
  },
};

let bookingMovingHash = {};
let users = null;
let plannerDispatcher = new PlannerDispatcher();
let SERVER_ADDRESS;

let bookingsCreationClicks = [];
let bookingsEditingClicks = [];
let ctrlPressed = false;

switch (process.env.REACT_APP_ENV) {
  case "production":
    SERVER_ADDRESS = "https://web.teambookapp.com";
    break;
  case "staging":
    SERVER_ADDRESS = "https://web.teambooktest.com";
    break;
  default:
    SERVER_ADDRESS = "http://localhost:3001";
    break;
}

const cable = ActionCable.createConsumer(`${SERVER_ADDRESS}/api/cable`);
const plannerActions = new UserPlannerActions();

const UsersPlanner = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const default_zoom = localStorage.getItem("default_zoom");

  const { filterValues, setFilterValue, filterType } = useTeambookFilter();
  const { filteredUsers, setFilteredUsers } = useFilteredUsersStore();

  const [zoom, setZoom] = useZoomStore((state) => [state.zoom, state.setZoom], shallow);
  const [account] = useAccountStore((state) => [state.account], shallow);
  const [profile] = useProfileStore((state) => [state.profile], shallow);

  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);
  const [
    setDayViewBookings,
    dayViewBookings,
    setCreateWindowOpened,
    addBookingToCreating,
    setCreationBookingDuration,
    clearCreationBookings,
  ] = useBookingsCreatingStore(
    (state) => [
      state.setDayViewBookings,
      state.dayViewBookings,
      state.setCreateWindowOpened,
      state.addBooking,
      state.setDuration,
      state.clearBookings,
    ],
    shallow
  );

  const [setSelectedProject, setSelectedTimeOff, selectedProject, selectedTimeOff, setProjectsType, projectsType] =
    useSelectedProjectStore(
      (state) => [
        state.setSelectedProject,
        state.setSelectedTimeOff,
        state.selectedProject,
        state.selectedTimeOff,
        state.setProjectsType,
        state.projectsType,
      ],
      shallow
    );

  const [addBookingToEdit, clearBookingsFromEdit, setBookingsEditing, setEditWindowOpened, removeBookingFromEditing] =
    useBookingsEditingStore(
      (state) => [
        state.addBooking,
        state.clearBookings,
        state.setBookings,
        state.setEditWindowOpened,
        state.removeBooking,
      ],
      shallow
    );

  const [setShiftPressed] = useShiftPressedStore((state) => [state.setShiftPressed], shallow);

  const [bookings, setBookings] = useBookingsStore((state) => [state.bookings, state.setBookings], shallow);
  const [date, setDate] = useDateStore((state) => [state.date, state.setDate], shallow);
  const [teams, setTeams] = useState();
  const [teamId, setTeamId] = useState();
  const [projects, setProjects] = useState();
  const [clients, setClients] = useState([]);
  const [tags, setTags] = useState([]);
  const [loadingBookings, setLoadingBookings] = useState(false);
  const [weekendsHidden, setWeekendsHidden] = useState(!(localStorage.getItem(lsKeys.WEEKENDS_HIDDEN_KEY) === "false"));
  const [addUserWindowOpened, setAddUserWindowOpened] = useState(false);
  const [hideOtherUsers, setHideOtherUsers] = useState(
    localStorage.getItem(lsKeys.PLANNER_HIDE_OTHERS) === "true" || false
  );

  const [emptyFiltered, setEmptyFiltered] = useState(false);
  const [removeUser, setRemoveUser] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [repetitionQuestionDialogOpened, setRepetitionQuestionDialogOpened] = useState(false);
  const [repetitionEditQuestionDialogOpened, setRepetitionEditQuestionDialogOpened] = useState(false);
  const [onboardingOpened, setOnboardingOpened] = useState(false);
  const [userSwapId, setUserSwapId] = useState(null);
  const [userRows, setUserRows] = useState([]);
  const [editingData, setEditingData] = useState({});
  const [allUsers, setAllUsers] = useState();
  const [modalRepetitionReachedOpen, setModalRepetitionReachedOpen] = useState(false);
  const [activeProjects, setActiveProjects] = useState([]);
  const [teamNotFound, setTeamNotFound] = useState(false);
  const [emptyPlanner, setEmptyPlanner] = useState(false);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [moveQuestionDialogOpen, setMoveQuestionDialogOpen] = useState(false);
  const [disableTooltip, setDisableTooltip] = useState(localStorage.getItem(lsKeys.TOOLTIP_HIDDEN) === "true");
  const [userOrder, setUserOrder] = useState(localStorage.getItem(lsKeys.USER_ORDER));
  const [bookingFormat, setBookingFormat] = useState(localStorage.getItem(lsKeys.BOOKING_FORMAT) || 2);
  const [isSwappingUsers, setIsSwappingUsers] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [editUserTab, setEditUserTab] = useState("general");
  const [taskArray, setTaskArray] = useState([]);
  const [authPage, setAuthPage] = useState();
  const [deleteRepetitionModalOpen, setDeleteRepetitionModalOpen] = useState(false);

  const weekendStart = 6;
  const role = localStorage.getItem("tb-role") || "regular";
  const usersRef = useRef([]);
  const creationRef = useRef([]);
  const editingRef = useRef([]);
  const tooltipRef = useRef([]);
  const bookingRightClickMenuRef = useRef({});
  const plannerRightClickRef = useRef();
  const didYouKnowRef = useRef({});
  const firstPlannerDate = GetStartDateForPlannerDate(date, zoom);
  const createBookingsRef = useRef([]);
  const multipleSelectRef = useRef(null);
  const weekendInfoRef = useRef(null);

  const applyFilteredUsers = (users) => {
    setFilteredUsers(users.filter((user) => usersWorkingScheduleSum(user) > 0));
  };

  window.onfocus = function () {
    setShiftPressed(false);
  };

  useEffect(() => {
    if (account) {
      if (cable.subscriptions.subscriptions.length === 0 && Array.from(bookings.keys()).length > 1) {
        cable.subscriptions.create(
          { channel: "BookingsChannel", account_id: account.id },
          {
            received: (bookingsData) => handleSocketBookings(bookingsData),
          }
        );
      }
    }
  }, [account, bookings, profile]);

  useEffect(() => {
    setShiftPressed(false);
    closeEditWindow();
    closeCreationWindow();
  }, []);

  // Fetch data
  useEffect(() => {
    if (!teamId) {
      GetTeamsRequest(setTeams, true);
      GetActiveProjects(setActiveProjects);
      Api.Tasks.get().then((res) => setTaskArray([...res.data]));
      return;
    }

    TeamChangedActions(setClients, setTags, setOnboardingOpened, setAllUsers);
  }, [teamId]);

  useEffect(() => {
    if (profile.role === "admin" && props.isAdminRole) {
      props.setIsAdminRole(true);
    }
  }, [profile]);

  useEffect(() => {
    if (localStorage.getItem(lsKeys.GTM_MOVE_ACTION)) {
      setAuthPage(localStorage.getItem(lsKeys.GTM_MOVE_ACTION));
      document.title = "Teambook";
      localStorage.removeItem(lsKeys.GTM_MOVE_ACTION);
    }
  }, []);

  useEffect(() => {
    let users = teams?.find((team) => team.id === teamId)?.team_users;

    if (users && users.length > 0 && date) {
      GetBookingsRequest(setLoadingBookings, users, setProjects, date, zoom, setBookings);

      changeOrder(localStorage.getItem(lsKeys.USER_ORDER));

      Api.Users.post_settings({
        last_planner_team_id: teamId,
      });
    }
  }, [teams, teamId, date, zoom]);

  useEffect(() => {
    if (addUser) {
      GetTeamsRequest(setTeams, true);
      setAddUser(false);
    }
  }, [addUser]);

  useEffect(() => {
    creationRef.current = [0];
    editingRef.current = [0];
    tooltipRef.current = [0];

    localStorage.setItem(lsKeys.LAST_PLANNER_TYPE, "planners");

    setFilterValue(JSON.parse(localStorage.getItem(lsKeys.LAST_PLANNER_FILTERS)) || []);
  }, []);

  useEffect(() => {
    if (!teams) return;

    const savedLastId = localStorage.getItem(lsKeys.LAST_TEAMID_KEY) || teams[0]?.id;
    const firstInTeams = teams[0]?.id;

    let id = props.id || (teams.some((team) => team.id === +savedLastId) ? savedLastId : undefined) || firstInTeams;

    if (!teams.some((team) => team.id === +id)) {
      id = null;
      setTeamNotFound(true);
    } else if (teams.find((team) => team.id === +id).team_users.length === 0) {
      setEmptyPlanner(true);
    }

    setTeamId(+id);

    setZoom(+default_zoom || PersistedState.getTeamZoom(id) || 28);
  }, [teams, props.id]);

  // Set current date
  useEffect(() => {
    if (date || !zoom) return;

    const d = new Date(props.date || new Date());

    let offsetDate = GetWeekMonday(OffsetDays(d, -14));

    if (zoom === 1) {
      offsetDate = new Date();
    } else if (zoom === 90) {
      offsetDate = GetWeekMonday(OffsetDays(d, -28));
    }

    setDate(!isNaN(d) ? offsetDate : undefined);
  }, [props.date, zoom]);

  // Save last opened team id
  useEffect(() => {
    if (teamId && teams.some((team) => team.id === teamId)) {
      PersistedState.setLastTeamId(teamId);
    }
  }, [teamId]);

  // Save team zoom
  useEffect(() => {
    if (zoom && teamId && teams.some((team) => team.id === teamId)) {
      PersistedState.setTeamZoom(teamId, +default_zoom || zoom);
    }
  }, [zoom]);

  // Make path
  useEffect(() => {
    if (!teamId || !date) return;

    if (zoom === 1) {
      navigate(`/planners/${teamId}/${ToDateString(date)}`, {
        replace: true,
      });
    } else {
      navigate(`/planners/${teamId}/${ToDateString(GetWeekMonday(date))}`, {
        replace: true,
      });
    }
  }, [teamId, date]);

  useEffect(() => {
    if (filterValues.length > 0 && teams?.find((team) => team.id === teamId) && projects && !hideOtherUsers) {
      applyFilteredUsers(
        sortAlphabetically(
          userOrder,
          "last_name",
          filterUsers(
            filterValues,
            teams?.find((team) => team.id === teamId)?.team_users,
            setEmptyFiltered,
            clients,
            bookings,
            tags,
            projects,
            filterType,
            t
          )
        )
      );

      let _filteredProjects = [];

      filterValues.forEach((filteredOption) => {
        if (filteredOption.type === t("planning.filter_projects")) {
          _filteredProjects.push(filteredOption);
        } else if (filteredOption.type === t("planning.filter_clients")) {
          let client = clients.filter((client) => filteredOption.name === client.name)[0];

          if (client) {
            _filteredProjects.push(...projects.filter((project) => project.client_id === client.id));
          }
        }
      });

      setFilteredProjects(_filteredProjects);
    } else {
      setFilteredProjects([]);
      setEmptyFiltered(false);
    }
  }, [filterValues, teamId, tags, clients, bookings, projects, filterType, teams, hideOtherUsers]);

  useEffect(() => {
    if (filteredProjects.length > 0) {
      let elements = document.getElementsByClassName("booking");

      Array.from(elements).forEach((element) => {
        element.classList.add("blurred-proj");
      });

      filteredProjects.forEach((project) => {
        elements = document.getElementsByClassName(`proj-${project.id}`);

        Array.from(elements).forEach((element) => {
          element.classList.remove("blurred-proj");
        });
      });
    } else {
      let elements = document.getElementsByClassName("booking blurred-proj");

      Array.from(elements).forEach((element) => {
        element.classList.remove("blurred-proj");
      });
    }
  }, [filteredProjects, userRows]);

  useEffect(() => {
    if (useSelectedProjectStore.getState().selectedProject === null) {
      if (activeProjects?.length > 0) {
        let project =
          activeProjects.filter((project) => project.kind !== "time_off" && project.active === true)[0] || null;

        setSelectedProject(project);
      }
    }
  }, [projects, activeProjects]);

  useEffect(() => {
    if (!filteredUsers || !teams || !teamId || !projects || !profile) {
      return;
    }

    let plannerUsers = [];

    if (hideOtherUsers && teams?.find((team) => team.id === teamId).team_users.find((user) => user.id === profile.id)) {
      plannerUsers = [profile];
    } else {
      plannerUsers = teams?.find((team) => team.id === teamId).team_users;
    }

    if (userOrder === "a" || userOrder === "z") {
      if (filterValues.length > 0) {
        applyFilteredUsers(
          sortAlphabetically(
            userOrder,
            "last_name",
            filterUsers(filterValues, plannerUsers, setEmptyFiltered, clients, bookings, tags, projects, filterType, t)
          )
        );
      } else {
        applyFilteredUsers(sortAlphabetically(userOrder, "last_name", plannerUsers));
      }
    } else {
      if (filterValues.length > 0) {
        applyFilteredUsers(
          filterUsers(filterValues, plannerUsers, setEmptyFiltered, clients, bookings, tags, projects, filterType, t)
        );
      } else {
        if (role === "self_planner") {
          applyFilteredUsers([
            plannerUsers.find((user) => user.id === profile.id),
            ...plannerUsers.filter((user) => user.id !== profile.id),
          ]);
        } else {
          applyFilteredUsers(plannerUsers);
        }
      }
    }
  }, [userOrder, teams, teamId, filterValues, filterType, projects, profile, hideOtherUsers]);

  useEffect(() => {
    if (selectedProject && projectsType !== "time_off") {
      localStorage.setItem(lsKeys.SAVED_PROJECT, JSON.stringify(selectedProject));
    }

    if (selectedTimeOff && projectsType === "time_off") {
      localStorage.setItem(lsKeys.SAVED_TIME_OFF, JSON.stringify(selectedTimeOff));
    }

    if (projectsType !== "time_off") {
      localStorage.setItem(lsKeys.SAVED_PROJECT_TYPE, "default");
    } else {
      localStorage.setItem(lsKeys.SAVED_PROJECT_TYPE, "time_off");
    }
  }, [selectedProject, selectedTimeOff, projectsType]);

  useEffect(() => {
    let rows = [];

    if (teams) {
      team = teams.find((team) => team.id === teamId);
    }

    if (teams?.find((team) => team.id === teamId)?.team_users.length === 0) {
    } else if (filteredUsers.length === 0 && !emptyFiltered && team && profile) {
      applyFilteredUsers(getAllPlannerUsers(team));
    } else if (removeUser && filteredUsers.length > 0) {
      applyFilteredUsers(getAllPlannerUsers(team));
      setRemoveUser(false);
      usersRef.current = [];
    } else if (addUser && filteredUsers.length > 0) {
      applyFilteredUsers(getAllPlannerUsers(team));
      setAddUser(false);
    } else if (projects && teams && teamId && teams.find((team) => team.id === teamId).team_users.length && profile) {
      users = filteredUsers
        .filter(
          (user, index, self) => index === self.findIndex((t) => t.id === user.id) && usersWorkingScheduleSum(user) > 0
        )
        .flat();

      if (hideOtherUsers) {
        if (profile && users.find((user) => user.id === profile.id)) {
          users = [profile];

          if (filteredUsers[0]?.id !== profile.id) {
            applyFilteredUsers([profile]);
          }
        } else {
          users = [];
        }
      }

      createBookingsRef.current = [];
      usersRef.current = [];

      let topOffset = 0;

      for (let i = 0; i < users.length; i++) {
        rows.push(
          <UserRow
            createBookingsRef={createBookingsRef}
            key={`user-row-${users[i].id}-${filteredUsers.length}`}
            index={i}
            user={users[i]}
            mouseDowned={mouseDowned}
            mouseUped={mouseUped}
            weekendsHidden={weekendsHidden}
            deleteBookingsRequest={deleteBookingsRequest}
            ref={(el) => (usersRef.current[i] = el)}
            setUserSwapId={setUserSwapId}
            userSwapId={userSwapId}
            swapUsersRequest={swapUsersRequest}
            firstPlannerDate={firstPlannerDate}
            activeProjects={activeProjects}
            changeTooltip={changeTooltip}
            topOffset={0}
            mouseMoved={mouseMoved}
            selectBooking={selectBooking}
            moveBookingRequest={moveBookingRequest}
            addBookingToCreation={addBookingToCreation}
            tooltipRef={tooltipRef}
            filteredUsers={users}
            bookingFormat={bookingFormat}
            teams={teams}
            setIsSwappingUsers={setIsSwappingUsers}
            changeContextMenu={changeContextMenu}
            changePlannerRightClickRef={changePlannerRightClickRef}
            updateBookingsRequest={updateBookingsRequest}
            virtualProps={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "fit-content",
              height: calculateUserRowHeight(users[i]),
              transform: `translateY(${topOffset}px)`,
            }}
            mouseClickDayView={mouseClickDayView}
            tasks={taskArray}
            weekendInfoRef={weekendInfoRef}
          />
        );

        let rowHeight = (usersMaxScheduleTime(users[i]) / 60) * formatMapping[bookingFormat] + 17;

        if (zoom === 1) {
          rowHeight = DAY_VIEW_HEIGHT;
        }

        topOffset += rowHeight;
      }
    }

    setUserRows(rows);

    plannerDispatcher = new PlannerDispatcher(usersRef, filteredUsers);
  }, [
    teams,
    teamId,
    projects,
    activeProjects,
    filteredUsers,
    userSwapId,
    weekendsHidden,
    disableTooltip,
    bookingFormat,
    profile,
    date,
    zoom,
    hideOtherUsers,
    taskArray,
  ]);

  const handleSocketBookings = (bookingsData) => {
    const allBookings = useBookingsStore.getState().bookings;

    return socketHandler({
      socketData: bookingsData,
      date: date,
      zoom: zoom,
      bookings: allBookings,
      setBookings: setBookings,
      plannerDispatcher: plannerDispatcher,
      getUserIndex: getUserIndex,
      usersRef: usersRef,
      teams: teams,
      teamId: teamId,
      projects: projects,
      activeProjects: activeProjects,
      setProjects: setProjects,
      setActiveProjects: setActiveProjects,
      userId: profile?.id,
    });
  };

  const removeUserFromTeam = () => {
    DeleteUserFromTeamRequest(teamId, [editingUser.id], false).then(() => GetTeamsRequest(setTeams, true));
    setEditingUser(null);
  };

  const calculateUserRowHeight = (user) => {
    if (zoom === 1) {
      return `${DAY_VIEW_HEIGHT}px`;
    } else {
      return `${(usersMaxScheduleTime(user) / 60) * formatMapping[bookingFormat] + 17}px`;
    }
  };

  const calculateFilteredUsers = () => {
    let plannerUsers = teams?.find((team) => team.id === teamId)?.team_users.filter((user) => user.id !== profile.id);

    applyFilteredUsers(
      filterUsers([], plannerUsers, setEmptyFiltered, clients, bookings, tags, projects, filterType, t)
    );
  };

  const sortAlphabetically = (type, field, array) => {
    let filteredArray = [...array];

    if (type === "a") {
      filteredArray.sort((a, b) => (a[field].toUpperCase() > b[field].toUpperCase() ? 1 : -1));
    } else if (type === "z") {
      filteredArray.sort((a, b) => (a[field].toUpperCase() > b[field].toUpperCase() ? -1 : 1));
    }

    return filteredArray;
  };

  const swapUsersRequest = () => {
    Api.Teams.swap_users(teamId, useMoveUserStore.getState().startUser.id, useMoveUserStore.getState().endUser.id)
      .then((response) => {
        setTeams([...teams.filter((t) => t.id !== response.data.id), response.data]);
        team = teams.find((team) => team.id === teamId);
        applyFilteredUsers(response.data.team_users);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeDate = (date) => {
    if (useZoomStore.getState().zoom === 1) {
      setDate(date);
    } else {
      setDate(GetWeekMonday(OffsetDays(date, -14)));
    }

    closeEditWindow();
    closeCreationWindow();
  };

  const planningBlocked = (account) => {
    return account?.active_projects > account?.subscription.projects_available;
  };

  const changeWeekendsHidden = (newState) => {
    setWeekendsHidden(newState);
    localStorage.setItem(lsKeys.WEEKENDS_HIDDEN_KEY, newState.toString());
  };

  const changeTooltipDisabling = (newState) => {
    setDisableTooltip(newState);
    localStorage.setItem(lsKeys.TOOLTIP_HIDDEN, newState.toString());
  };

  const changeOrder = (newOrder) => {
    setUserOrder(newOrder);
    localStorage.setItem(lsKeys.USER_ORDER, newOrder);
  };

  const getUserIndex = (userId) => {
    let result;

    useFilteredUsersStore.getState().filteredUsers.forEach((user, i) => {
      if (user.id === userId) {
        result = i;
      }
    });

    return result;
  };

  const getAllPlannerUsers = (team) => {
    if (hideOtherUsers && team?.team_users.find((user) => user.id === profile.id)) {
      return [profile];
    } else {
      return team?.team_users || [];
    }
  };

  const openCreationWindow = (project = null) => {
    if (!useShiftPressedStore.getState().shiftPressed) {
      setCreateWindowOpened(true);
    }

    if (project) {
      setSelectedProject(project);
    }
  };

  const changeTooltip = (newTooltip) => {
    if (!useShiftPressedStore.getState().shiftPressed) {
      TooltipChanger(newTooltip);
    }
  };

  const changeContextMenu = (newMenu) => {
    bookingRightClickMenuRef.current.setOpen(newMenu.open);
    bookingRightClickMenuRef.current.setPosX(newMenu.posX);
    bookingRightClickMenuRef.current.setPosY(newMenu.posY);
    bookingRightClickMenuRef.current.setBooking(newMenu.booking);
  };

  const changePlannerRightClickRef = (newMenu) => {
    plannerRightClickRef.current.setOpen(newMenu.open);
    plannerRightClickRef.current.setPosX(newMenu.posX);
    plannerRightClickRef.current.setPosY(newMenu.posY);
    plannerRightClickRef.current.setCopyUserId(newMenu.userId);
    plannerRightClickRef.current.setCopyUserIndex(newMenu.index);

    let calculatedOffsetX = 0;
    let dateIteration = 0;

    const dayWidth = getCellWidthForZoom(zoom);
    const weekendWidth = weekendsHidden ? 10 : getCellWidthForZoom(zoom);

    while (calculatedOffsetX < newMenu.posX - 150 + $("#planner-scroll-list").scrollLeft()) {
      calculatedOffsetX += dateIteration % 7 < 5 ? dayWidth : weekendWidth;
      dateIteration++;
    }

    let copyDate = OffsetDays(date, dateIteration - 1);

    plannerRightClickRef.current.setCopyDate(copyDate);
  };

  const closeCreationWindow = () => {
    setCreateWindowOpened(false);
    createBookingsRef.current.forEach((ref) => {
      ref?.updateBookings([]);
    });
    clearCreationBookings();
    bookingsCreationClicks = [];
    setDayViewBookings([]);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === CONTROL_KEY || e.keyCode === APPLE_META_KEY) {
      ctrlPressed = true;
    }
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === CONTROL_KEY || e.keyCode === APPLE_META_KEY) {
      ctrlPressed = false;
    }
  };

  const mouseClickDayView = (userIndex, hour) => {
    if (useCopyBookingsStore.getState().copyModeActive && useCopyBookingsStore.getState().copyBookingIds.length > 0) {
      let new_bookings = useBookingsStore.getState().bookings;

      Api.Bookings.copy(useCopyBookingsStore.getState().copyBookingIds, users[userIndex].id, ToDateString(date)).then(
        (response) => {
          setBookings([...new_bookings, ...response.data]);

          plannerDispatcher.afterCreationBookingAssignment([userIndex], usersRef, teams, teamId, bookings);

          plannerActions.userIndexStart = null;
          plannerActions.userDateStart = null;
        }
      );
    } else {
      setDayViewBookings([[userIndex, hour]]);
      setCreationBookingDuration(account?.default_booking_duration / 60);
      closeEditWindow();
      openCreationWindow();
    }
  };

  const mouseDowned = (offsetX, offsetY, users) => {
    plannerActions.mouseDowned(offsetX, offsetY, users, multipleSelectRef, weekendsHidden, bookingFormat);
  };

  const mouseMoved = (offsetX, offsetY, users) => {
    plannerActions.mouseMoved(
      offsetX,
      offsetY,
      users,
      weekendsHidden,
      multipleSelectRef,
      bookingFormat,
      createBookingsRef
    );
  };

  const mouseUped = (offsetX, offsetY, users, e) => {
    plannerActions.mouseUped(
      offsetX,
      offsetY,
      users,
      e,
      multipleSelectRef,
      selectBooking,
      plannerDispatcher,
      weekendsHidden,
      bookingFormat,
      createBookingsRef,
      usersRef,
      teams,
      teamId,
      closeEditWindow,
      openCreationWindow
    );
  };

  const addBookingToCreation = (userIndex, date) => {
    addBookingToCreating([userIndex, date]);

    createBookingsRef.current[userIndex].addBooking([userIndex, date]);
    setCreationBookingDuration(account?.default_booking_duration / 60);
    closeEditWindow();
    openCreationWindow();
  };

  const selectBooking = (booking) => {
    if (useCopyBookingsStore.getState().copyModeActive) {
      // setCopyBookingId(booking.id);
    } else if (
      ctrlPressed &&
      useBookingsEditingStore.getState().bookingsEditing.some((editingBooking) => editingBooking.id === booking.id)
    ) {
      if (useBookingsEditingStore.getState().bookingsEditing.length === 1) {
        clearBookingsFromEdit();
        setEditWindowOpened(false);
      } else {
        removeBookingFromEditing(booking.id);
      }
    } else if (ctrlPressed || useShiftPressedStore.getState().shiftPressed) {
      addBookingToEdit(booking);
      setEditWindowOpened(true);
    } else {
      clearBookingsFromEdit();
      addBookingToEdit(booking);
      setEditWindowOpened(true);

      closeCreationWindow();
    }

    const project =
      projects.find((pr) => pr.id === booking.project_id) || activeProjects.find((pr) => pr.id === booking.project_id);

    if (project) {
      if (project.kind === "time_off") {
        setSelectedTimeOff(project);
      } else {
        setSelectedProject(project);
      }
    } else {
      setSelectedProject(activeProjects[0]);
    }
  };

  const noUsersTeam = () => {
    return teams?.find((team) => team.id === teamId)?.team_users?.length === 0;
  };

  const deleteBookingsRequest = async (deletionBookings) => {
    if (deletionBookings.length > 1) {
      setInAppNotification(
        t("notifications.planning.bookings_deleted", {
          bookingsAmount: deletionBookings.length,
        })
      );
    }

    _deleteBookingsRequest(
      deletionBookings,
      setBookingsEditing,
      setRepetitionQuestionDialogOpened,
      date,
      zoom,
      setBookings,
      closeCreationWindow,
      bookings,
      setEditWindowOpened,
      closeEditWindow,
      teams,
      teamId,
      usersRef,
      filteredUsers,
      setProjects,
      setActiveProjects,
      projects,
      activeProjects,
      tooltipRef
    );
  };

  const repetitionChanged = (repetition, bookingIds, booking) => {
    if (bookingIds.length > 1) return false;

    if (repetition.on === false) {
      deleteRepetitionClick();
      return false;
    }

    const bookingRepetition = booking.repetition[0];

    if (repetition.type === bookingRepetition.kind) {
      if (
        repetition.ends_after &&
        repetition.ends_after === bookingRepetition.ends_after &&
        repetition.every === bookingRepetition.every
      ) {
        return false;
      }

      if (repetition.ends_on && repetition.ends_on === bookingRepetition.ends_on) {
        return false;
      }
    }

    return true;
  };

  const updateBookingsRequest = (
    duration,
    tentative,
    location,
    startTime,
    comment,
    usesBudget,
    bookingIds,
    bookingProject,
    task,
    repetition
  ) => {
    let skipQuestion = true;
    let bookingsToEdit;
    let projectOfEditingBooking;

    //TODO: simply set state instead of passing bookingIds
    if (bookingIds) {
      bookingsToEdit = bookings.filter((b) => bookingIds.includes(b.id));
      projectOfEditingBooking = bookingProject;
    } else {
      bookingsToEdit = useBookingsEditingStore.getState().bookingsEditing;
      projectOfEditingBooking =
        useSelectedProjectStore.getState().projectsType === "default"
          ? useSelectedProjectStore.getState().selectedProject
          : useSelectedProjectStore.getState().selectedTimeOff;
    }

    bookingsToEdit.forEach((booking) => {
      if (booking.repetition) {
        let oldEditData = {};

        oldEditData.projectId = projectOfEditingBooking.id;
        oldEditData.duration = duration;
        oldEditData.tentative = tentative;
        oldEditData.location = location;
        oldEditData.startTime = startTime;
        oldEditData.comment = comment;
        oldEditData.bookingsToUpdate = bookingsToEdit;
        oldEditData.usesBudget = usesBudget;
        oldEditData.task_id = task;

        if (repetition && repetitionChanged(repetition, bookingsToEdit, booking)) {
          oldEditData.repetition = repetition;
        } else {
          setRepetitionEditQuestionDialogOpened(true);
          skipQuestion = false;
        }

        setEditingData(oldEditData);
      }
    });

    //TODO: fix projectOfEditingBooking being NULL
    if (skipQuestion) {
      UpdateBookingsRequest(
        projectOfEditingBooking.id,
        duration,
        tentative,
        location,
        startTime,
        comment,
        bookingsToEdit,
        useBookingsStore.getState().bookings,
        setBookings,
        closeEditWindow,
        false,
        usesBudget,
        task,
        repetition,
        date,
        zoom
      ).then((res) => {
        let bookingsToUpdate = bookingsToEdit;

        if (repetition) {
          bookingsToUpdate = res.data;
        }

        plannerDispatcher.afterCreationBookingAssignment(
          bookingsToUpdate.map((booking) => getUserIndex(booking.user_id)),
          usersRef,
          teams,
          teamId,
          bookings
        );

        let projectIds = [projectOfEditingBooking.id];

        bookingsToUpdate.map((booking) => {
          if (!projectIds.includes(booking.project_id)) {
            projectIds.push(booking.project_id);
          }
        });

        projectIds.map((projectId) => {
          Api.Projects.get(projectId).then((response) => {
            if (response.data.estimated) {
              setProjects(updateValueWithId(projectId, projects, response.data));
              setActiveProjects(updateValueWithId(projectId, activeProjects, response.data));
            }
          });
        });
      });
    }
  };

  const deleteRepetitionRequest = () => {
    Api.Bookings.delete_repetition(useBookingsEditingStore.getState().bookingsEditing[0].id).then((res) => {
      const deletedBookingIds = res.data.deleted;
      const usersToRefreshIds = res.data.user_ids;
      const bookingLeft = res.data.booking_left;

      let newBookings = bookings.filter((booking) => !deletedBookingIds.includes(booking.id));

      res.data.repetition_bookings.forEach((booking) => {
        newBookings[newBookings.findIndex((b) => b.id === booking.id)] = booking;
      });

      newBookings[newBookings.findIndex((b) => b.id === bookingLeft.id)] = bookingLeft;

      setBookings(newBookings);

      plannerDispatcher.afterCreationBookingAssignment(
        usersToRefreshIds.map((userId) => getUserIndex(userId)),
        usersRef,
        teams,
        teamId,
        bookings
      );

      Api.Projects.get(bookingLeft.project_id).then((response) => {
        if (response.data.estimated) {
          setProjects(updateValueWithId(bookingLeft.project_id, projects, response.data));
          setActiveProjects(updateValueWithId(bookingLeft.project_id, activeProjects, response.data));
        }
      });

      closeEditWindow();
    });
  };

  const deleteRepetitionClick = () => {
    setDeleteRepetitionModalOpen(true);
  };

  const getAllBookingsFromRefs = () => {
    return createBookingsRef.current
      .filter((ref) => ref)
      .filter((ref) => ref.bookings.length)
      .map((ref) => {
        return ref.bookings;
      })
      .flat();
  };

  const createBookingsRequest = (
    project,
    duration,
    tentative,
    startTime,
    comment,
    location,
    repetition,
    usesBudget,
    task,
    weekendCreating = false
  ) => {
    const bookingsToCreate = zoom === 1 ? dayViewBookings : getAllBookingsFromRefs();

    if (!project.active) {
      setInAppNotification(t("notifications.planning.deactivated_creating"));
      return;
    }

    const durationCreating = createBookingsRef.current[0]?.duration || duration;

    if (durationCreating > 0 && durationCreating < 24 && (bookingsToCreate.length > 0 || dayViewBookings.length > 0)) {
      CreateBookingsRequest(
        project,
        duration,
        tentative,
        startTime,
        comment,
        date,
        zoom,
        bookings,
        setBookings,
        false,
        repetition,
        filteredUsers,
        teamId,
        location,
        zoom === 1 ? dayViewBookings : bookingsToCreate,
        durationCreating,
        usesBudget,
        task,
        weekendCreating
      ).then((response) => {
        if (response.data.response.status === "limit_reached") {
          setModalRepetitionReachedOpen(true);
        }

        if (!!repetition.repetitionType) {
          setInAppNotification(t("notifications.planning.repetition"));
        } else if (response.data.response.bookings_amount > 1) {
          setInAppNotification(
            t("notifications.planning.bookings_created", {
              bookingsAmount: response.data.response.bookings_amount,
            })
          );
        }

        plannerDispatcher.afterCreationBookingAssignment(
          bookingsToCreate.map((options) => options[0]),
          usersRef,
          teams,
          teamId,
          bookings
        );

        closeCreationWindow();
        closeEditWindow();

        if (role === "self_planner") {
          let userIds = filteredUsers.map((user) => user.id);

          let usersCreating = Array.from(new Set(bookingsToCreate.map((b) => userIds[b[0]])));

          if (usersCreating.length > 1 || !usersCreating.includes(profile.id)) {
            setInAppNotification(t("notifications.planning.self_planner_creating_error"));
          }
        }

        Api.Projects.get(project.id).then((response) => {
          if (response.data.estimated) {
            setProjects(updateValueWithId(project.id, projects, response.data));
            setActiveProjects(updateValueWithId(project.id, activeProjects, response.data));
          }
        });
      });
    }
  };

  const moveBookingRequest = (hash) => {
    const bookingsEditing = useBookingsEditingStore.getState().bookingsEditing;

    if (role === "self_planner" && hash.to_user !== profile.id) {
      setInAppNotification(t("notifications.planning.self_planner_creating_error"));
      return;
    }

    if (bookingsEditingClicks.length > 1) {
      setMoveQuestionDialogOpen(true);
    } else {
      MoveBookingRequest(
        date,
        zoom,
        useBookingsStore.getState().bookings,
        setBookings,
        bookingsEditing,
        useFilteredUsersStore.getState().filteredUsers,
        closeEditWindow,
        teams,
        teamId,
        usersRef,
        useFilteredUsersStore.getState().filteredUsers,
        hash
      ).then((userIdsToUpdateRow) => {
        plannerDispatcher.afterCreationBookingAssignment(
          userIdsToUpdateRow.map((userId) => getUserIndex(userId)),
          usersRef,
          teams,
          teamId,
          bookings
        );
      });
    }
  };

  const closeEditWindow = () => {
    if (useBookingsEditingStore.getState().editWindowOpened) {
      setEditWindowOpened(false);
    }

    if (useBookingsEditingStore.getState().bookingsEditing.length) {
      setBookingsEditing([]);
    }

    bookingsEditingClicks = [];

    let elements = document.getElementsByClassName("blurred");

    Array.from(elements).forEach((element) => {
      element.classList.remove("blurred");
    });
  };

  const openAddUserWindow = () => {
    setAddUserWindowOpened(true);
  };

  const closeAddUserWindow = () => {
    setAddUserWindowOpened(false);
  };

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Shift") {
        setShiftPressed(true);
      }
    });

    document.addEventListener("keyup", (e) => {
      if (e.key === "Shift") {
        if (!useShiftPressedStore.getState().startSelected) {
          setShiftPressed(false);
        }
      }
    });
  }, []);

  const allRowsCreation = (creationDate) => {
    if (["contractor", "regular"].includes(role)) return;

    if (account.subscription.subscription_id === "Free" && account.subscription.projects_available === 10) {
      return;
    }

    if (useCopyBookingsStore.getState().copyModeActive) {
      return;
    }

    filteredUsers.forEach((user, i) => {
      let included = false;

      bookingsCreationClicks.forEach((array) => {
        if (array[0] === i && ToDateString(array[1]) === ToDateString(creationDate)) {
          included = true;
        }
      });

      if (!included) {
        bookingsCreationClicks.push([i, creationDate]);
      }
    });

    const scheduleValue = account?.schedule[creationDate.getDay()];

    createBookingsRef.current.forEach((bookingCreateRef) => {
      bookingCreateRef?.updateBookings([...bookingsCreationClicks]);

      bookingCreateRef?.updateDuration((scheduleValue[0] + scheduleValue[2]) / 60);
    });

    setCreationBookingDuration((scheduleValue[0] + scheduleValue[2]) / 60);

    if (activeProjects.find((pr) => pr.kind === "time_off")) {
      openCreationWindow(activeProjects.find((pr) => pr.kind === "time_off"));
      setProjectsType("time_off");
      setSelectedTimeOff(activeProjects.find((pr) => pr.kind === "time_off"));
    } else {
      openCreationWindow();
    }
  };

  const zoomLoaded = zoom;
  const isLoaded = !!account && !!teams && !!teamId && !loadingBookings && !!bookings && !!projects && !!profile;
  const isFound = teamId && date;
  let team = null;

  if (teamNotFound) {
    return <Navigate to="/team_not_found" />;
  }

  return (
    <PlannerContext.Provider
      value={{
        team: teams?.find((team) => team.id === teamId),
        filteredUsers: filteredUsers,
        projects: projects,
        clients: clients,
        users: allUsers,
        tags: tags,
        plannerDate: date,
        weekendsHidden: weekendsHidden,
      }}
    >
      {authPage && <div className="gtm-auth-container" id={`successful_auth_${authPage}`} />}

      <div
        style={{
          cursor: useCopyBookingsStore.getState().copyModeActive
            ? "url('../default_components/copy-white.svg')"
            : "unset",
        }}
        className="planner-page"
        onMouseUp={(e) => {
          mouseUped(getPlannerOffsetX(e), getPlannerOffsetY(e, zoom), [], e);
        }}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
      >
        <Header didYouKnowRef={didYouKnowRef} setOnboardingOpened={setOnboardingOpened} page={"planning"} />

        {!disableTooltip && <BookingTooltip ref={(el) => (tooltipRef.current[0] = el)} />}

        {zoomLoaded && teams?.length > 0 && (!!projects || emptyPlanner) && (
          <>
            <PlannerControlBar
              hideOtherUsers={hideOtherUsers}
              setHideOtherUsers={setHideOtherUsers}
              teams={teams}
              teamId={teamId}
              changeDate={changeDate}
              calculateFilteredUsers={calculateFilteredUsers}
              plannerView={t("planning.select_by_users")}
              openAddUserWindow={openAddUserWindow}
              filterFields={[
                t("planning.filter_users"),
                t("planning.filter_tags"),
                t("planning.filter_projects"),
                t("planning.filter_clients"),
                t("planning.filter_advanced"),
              ]}
              role={role}
              changeTooltipDisabling={changeTooltipDisabling}
              bookingFormat={bookingFormat}
              setBookingFormat={setBookingFormat}
              disableTooltip={disableTooltip}
              weekendsHidden={weekendsHidden}
              changeWeekendsHidden={changeWeekendsHidden}
              plannerDispatcher={plannerDispatcher}
              setBookings={setBookings}
              usersRef={usersRef}
              setEditWindowOpened={setEditWindowOpened}
              closeEditWindow={closeEditWindow}
              createBookingsRef={createBookingsRef}
              activeProjects={activeProjects}
              filterPage={"userPlanner"}
            />
          </>
        )}

        {!isLoaded && !emptyPlanner && <LoadingComponent />}

        {teams && teams?.length < 1 && <NoTeamsPlanner />}

        {isLoaded && filteredUsers.length === 0 && filterValues.length > 0 && <NoBookingsFiltered />}

        {isLoaded && !isFound && !(!teams || teams?.length < 1) && <NoMatch />}

        {noUsersTeam() && (
          <div style={{ top: 240 }} className="empty-filtered no-users-planner">
            <div className="empty-filtered-text">
              <p className="no-users-planner__text">{t("planning.no_users_planner")}</p>
              <TeambookBlueButton onClick={openAddUserWindow} text={t("planning.add_user")} />
            </div>
            <TeambookIcon color="blue" name={icons.EMPTY_FILTER_IMAGE} />
          </div>
        )}

        {isFound && (isLoaded || emptyPlanner) && !noUsersTeam() && (
          <>
            {teams.length === 0 && <p>{t("planning.no_teams")}</p>}

            {teams.length > 0 && (
              <>
                <div className="planner-grid" id="planner-grid">
                  {planningBlocked(account) ? (
                    <PlanningBlocked />
                  ) : (
                    <UsersPlannerGrid
                      allRowsCreation={allRowsCreation}
                      weekendsHidden={weekendsHidden}
                      weekendStart={weekendStart}
                      userOrder={userOrder}
                      changeOrder={changeOrder}
                      isSwappingUsers={isSwappingUsers}
                      rows={userRows}
                      cellSize={formatMapping[bookingFormat]}
                      overscan={20}
                      users={filteredUsers
                        .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id))
                        .flat()}
                      bookingFormat={bookingFormat}
                      setUserSwapId={setUserSwapId}
                      userSwapId={userSwapId}
                      swapUsersRequest={swapUsersRequest}
                      setEditingUser={setEditingUser}
                      mouseMoved={mouseMoved}
                      createBookingsRef={createBookingsRef}
                      multipleSelect={
                        <SelectBookingsArea
                          ref={multipleSelectRef}
                          mouseMoved={(e) =>
                            mouseMoved(getPlannerOffsetX(e), getPlannerOffsetY(e, zoom), filteredUsers, e)
                          }
                        />
                      }
                      closeCreationWindow={closeCreationWindow}
                    />
                  )}

                  <EditWindow
                    projects={activeProjects}
                    closeEditWindow={closeEditWindow}
                    deleteBookingsRequest={deleteBookingsRequest}
                    updateBookingsRequest={updateBookingsRequest}
                    taskArray={taskArray}
                    setTaskArray={setTaskArray}
                    deleteRepetitionClick={deleteRepetitionClick}
                    orderedPlannerUsers={filteredUsers}
                    createBookingsRequest={createBookingsRequest}
                    createBookingsRef={createBookingsRef}
                  />

                  <CreateWindow
                    projects={activeProjects}
                    closeCreationWindow={closeCreationWindow}
                    createBookingsRequest={createBookingsRequest}
                    createBookingsRef={createBookingsRef}
                    taskArray={taskArray}
                    setTaskArray={setTaskArray}
                    creationBookings={zoom !== 1 ? getAllBookingsFromRefs : () => dayViewBookings}
                    orderedPlannerUsers={filteredUsers}
                  />
                </div>
              </>
            )}
          </>
        )}

        {isLoaded && <DidYouKnow ref={didYouKnowRef} />}

        <MoveQuestionDialog
          open={moveQuestionDialogOpen}
          setOpen={setMoveQuestionDialogOpen}
          bookingMovingHash={bookingMovingHash}
          bookingsEditingClicks={bookingsEditingClicks}
          users={users}
          closeEditWindow={closeEditWindow}
          teams={teams}
          teamId={teamId}
          usersRef={usersRef}
          getUserIndex={getUserIndex}
          plannerDispatcher={plannerDispatcher}
        />

        {teams && (
          <AddUserDialog
            setAddUser={setAddUser}
            open={addUserWindowOpened}
            handleClose={closeAddUserWindow}
            teamId={teamId}
            teams={teams}
            setTeams={setTeams}
          />
        )}

        <RepetitionReachedModal
          isOpen={modalRepetitionReachedOpen}
          close={() => setModalRepetitionReachedOpen(false)}
        />

        <RepetitionDeleteQuestionDialog
          open={repetitionQuestionDialogOpened}
          setRepetitionQuestionDialogOpened={setRepetitionQuestionDialogOpened}
          setBookings={setBookings}
          closeCreationWindow={closeCreationWindow}
          setEditWindowOpened={setEditWindowOpened}
          closeEditWindow={closeEditWindow}
          teams={teams}
          teamId={teamId}
          usersRef={usersRef}
          setProjects={setProjects}
          setActiveProjects={setActiveProjects}
          activeProjects={activeProjects}
          filteredUsers={filteredUsers}
        />

        <RepetitionEditQuestionDialog
          filteredUsers={filteredUsers}
          open={repetitionEditQuestionDialogOpened}
          setRepetitionQuestionDialogOpened={setRepetitionEditQuestionDialogOpened}
          setBookings={setBookings}
          closeCreationWindow={closeCreationWindow}
          setEditWindowOpened={setEditWindowOpened}
          closeEditWindow={closeEditWindow}
          teams={teams}
          teamId={teamId}
          usersRef={usersRef}
          editingData={editingData}
          setProjects={setActiveProjects}
          plannerDispatcher={plannerDispatcher}
          projects={activeProjects}
        />

        <OnboardingComponent
          setOnboardingOpened={setOnboardingOpened}
          onboardingOpened={onboardingOpened}
          role={role}
          page={"planning"}
        />

        <MultipleSelectHelp />

        <BookingRightClick
          deleteBookingsRequest={deleteBookingsRequest}
          ref={bookingRightClickMenuRef}
          closeEditWindow={closeEditWindow}
          changeTooltip={changeTooltip}
          updateBookingsRequest={updateBookingsRequest}
        />

        <PlannerRightClick
          ref={plannerRightClickRef}
          plannerDispatcher={plannerDispatcher}
          teams={teams}
          usersRef={usersRef}
        />

        <WeekendInfo
          selectBooking={selectBooking}
          addBookingToCreation={addBookingToCreation}
          deleteBookingRequests={deleteBookingsRequest}
          ref={weekendInfoRef}
        />

        <TeambookDialogYesNo
          open={deleteRepetitionModalOpen}
          setOpen={setDeleteRepetitionModalOpen}
          question={t("planning.delete_repetition_question")}
          questionTitle={t("planning.delete_repetition_question_title")}
          yesCallback={deleteRepetitionRequest}
          noCallback={() => setDeleteRepetitionModalOpen(false)}
        />

        {editingUser && (
          <EditUser
            user={editingUser}
            tags={tags}
            setUserFormState={() => setEditingUser(null)}
            getAllUsers={(data) => {
              GetTeamsRequest(setTeams, true);
            }}
            teams={teams}
            userFormState={"edit"}
            plannerActions={
              <TeambookIcon
                name={icons.REMOVE_USER}
                alt="remove user"
                onClick={removeUserFromTeam}
                tooltipTitle={t("planning.remove_user")}
                color="alternative_default"
              />
            }
            tab={editUserTab}
            setTab={setEditUserTab}
          />
        )}
      </div>

      {profile && <UserFeedbackBanner />}
      {profile && <SwitchToPaidPlanBanner />}
    </PlannerContext.Provider>
  );
};

export default UsersPlanner;
